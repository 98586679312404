<template>
  <section>
    <div :class="isMobile ? 'wrapper-mobile' : 'wrapper'">
      <iframe
        v-if="show"
        src="https://email.contasinfinity.com.br/mailbox"
        frameborder="0"
      />
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    show: false
  }),
  computed: {
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  mounted () {
    const isApple = navigator.userAgent.match(
      /(iPad|iPhone|iPod)/g
    )

    if (isApple) {
      window.location.assign('https://email.contasinfinity.com.br/mailbox')
      return
    }

    this.show = true
  }
}
</script>

<style>
.wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 100px;
    overflow-y: hidden;
}
.wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    overflow-y: hidden;
}

.wrapper-mobile {
    position: relative;
    padding-bottom: 100%; /* 100% if bottom page */
    padding-top: 25px;
    height: 0;
}
.wrapper-mobile iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
}
</style>
